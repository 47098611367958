import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../Images/Logo_Landing.png";
import { Tooltip } from "@mui/material";

function Header() {
  const navigate = useNavigate();
  const location = useLocation();

  const hostname = window.location.hostname;
  const port = window.location.port;


  const domains = {
    // for local
    // tenantRoute: "localhost:5000",
    // agentRoute: "localhost:5001",

    // for live server
    // tenantRouteLive: "stage-app.reazy.com.au",
    // agentRouteLive: "stage-client.reazy.com.au",

    // new
    tenantRouteLive: "dev-client.reazy.com.au",
    agentRouteLive: "dev.reazy.com.au",
  };

  const isTenantDomain =
    (hostname === "localhost" && port === "5000") ||
    hostname === domains.tenantRouteLive;
  const isAgentDomain =
    (hostname === "localhost" && port === "5001") ||
    hostname === domains.agentRouteLive;

  const handleLogin = () => {
    if (isTenantDomain) {
      navigate("/tenant-login");
    } else if (isAgentDomain) {
      navigate("/agent-login");
    } else {
      navigate("/404");
    }
  };

  const handleSignUp = () => {
    if (isTenantDomain) {
      navigate("/tenant-register");
    } else if (isAgentDomain) {
      navigate("/agent/register/null");
    } else {
      navigate("/404");
    }
  };

  const handleLogoClick = (e) => {
    e.preventDefault();
    if (location.pathname !== "/landing") {
      navigate("/landing");
    }
  };

  return (
    <div className="Landing_header">
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container">
          <a className="navbar-brand" href="">
            <img src={Logo} alt="" onClick={(e)=>handleLogoClick(e)} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#mynavbar"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="mynavbar">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                {isTenantDomain ? (
                  <Link className="nav-link" to="/tenant-register">
                    Rent
                  </Link>
                ) : (
                  <Link className="nav-link" to="/404">
                    Rent
                  </Link>
                )}
              </li>
              <li className="nav-item">
                <Tooltip title="coming soon">
                  <Link className="nav-link" to="#">
                    Buy
                  </Link>
                </Tooltip>
              </li>
              <Tooltip title="coming soon">
                <Link className="nav-link" to="#">
                  Sell
                </Link>
              </Tooltip>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  data-bs-toggle="dropdown"
                >
                  Manage Property
                </a>
                <ul className="dropdown-menu">
                  {isTenantDomain ? (
                    <>
                      <li>
                        <Link className="dropdown-item" to="/tenant-login">
                          Tenant Login
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/tenant-register">
                          Tenant Register
                        </Link>
                      </li>
                    </>
                  ) : isAgentDomain ? (
                    <>
                      <li>
                        <Link className="dropdown-item" to="/agent-login">
                          Agent Login
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/agent/register/null">
                          Agent Register
                        </Link>
                      </li>
                    </>
                  ) : (
                    <>
                      <li>
                        <Link className="dropdown-item" to="/404">
                          Invalid Domain
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  data-bs-toggle="dropdown"
                >
                  Resources
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <Link className="dropdown-item" to="/contact-us">
                      Contact Us
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/about">
                      Our Story
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/FAQs">
                      FAQ’s
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/terms">
                      Terms & Conditions
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
            <form className="rt_btns ms-auto">
              <button
                className="btn white_btn"
                type="button"
                onClick={handleLogin}
              >
                Login
              </button>
              <button
                className="btn blue_btn"
                type="button"
                onClick={handleSignUp}
              >
                SignUp
              </button>
            </form>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
